<template>
	<view-component
	v-if="view == 'metodos-de-pago'"
	model_name="payment_method"></view-component>
</template>
<script>
export default {
	components: {
		ViewComponent: () => import('@/common-vue/components/view/Index')
	}
}
</script>